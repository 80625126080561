<template>
    <editor
        :api-key="key"
        :init="options"
        :disabled="readonly"
        v-model="content"
        @onInit="handleOnInit"
    />
</template>
<script>
    import Http from '@/utils/httpClient';

    export default {
        name: 'tiny-editor',
        components: {
            editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            readonly: {
                type: Boolean,
                default: false
            },
            height: {
                type: [Number, String],
                default: '300',
            },
            placeholder: {
                type: String,
                default: 'Type here...',
            },
            minHeight: {
                type: Number,
                default: 24,
            },
            templates: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data: function () {
            return {
                content: this.value, // default to the passed value
                editor: false,
                dialog: null,
                key: process.env.VUE_APP_TINY_KEY
            }
        },
        computed: {
            options() {
                return {
                    schema: 'html5',
                    menubar: false,
                    inline: true,
                    resize: false,
                    plugins: [
                        'autolink',
                        'codesample',
                        'link',
                        'lists',
                        'media',
                        'table',
                        'image',
                        'imagetools',
                        'quickbars',
                        'codesample',
                        'help',
                        'template',
                        'paste',
                        'searchreplace',
                        'print',
                        'pagebreak',
                        'textpattern',
                    ],
                    image_title: true,
                    image_caption: true,
                    toolbar: false,
                    quickbars_insert_toolbar: false,
                    quickbars_selection_toolbar: 'bold italic underline | numlist bullist | formatselect | blockquote quicklink',
                    contextmenu: 'undo redo | template quickimage imagetools media link codesample inserttable | cell row column deletetable | searchreplace | paste pastetext | pagebreak | print | help',
                    height: this.height,
                    placeholder: this.placeholder,
                    min_height: this.minHeight,
                    autosave_ask_before_unload: false,
                    visual: false,
                    apply_source_formatting: true,
                    link_context_toolbar: true,
                    paste_data_images: true,
                    templates: this.templates,
                    file_picker_types: 'file image media',
                    file_picker_callback: this.filePickerCallback,
                    images_upload_handler: this.imagesUploadHandler,
                    default_link_target: '_blank',
                    setup: (editor) => {
                        editor.ui.registry.addContextMenu('quickimage', {
                            update() {
                                return ['image'];
                            }
                        });
                        editor.on('OpenWindow', (e) => {
                            this.dialog = e.dialog;
                        })
                    }
                }
            }
        },
        watch: {
            value(newValue) {
                this.content = newValue;
            },
            content(newValue) {
                this.$emit('input', newValue);
            }
        },
        methods: {
            handleOnInit(event, editor) {
                this.editor = editor;

                // if readonly, set state
                if (this.readonly) {
                    this.editor.setMode('readonly');
                }
            },
            filePickerCallback(cb, value, meta) {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', '*/*');
                
                if (meta.filetype === 'image') {
                    input.setAttribute('accept', 'image/*');
                }

                /*
                Note: In modern browsers input[type="file"] is functional without
                even adding it to the DOM, but that might not be the case in some older
                or quirky browsers like IE, so you might want to add it to the DOM
                just in case, and visually hide it. And do not forget do remove it
                once you do not need it anymore.
                */
                
                input.onchange = () => {                    
                    const file = input.files[0];
                    
                    const reader = new FileReader();
                    reader.onload = () => {
                        /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                        */                        
                        const id = `blobid${(new Date()).getTime()}`;
                        const { blobCache } = this.editor.editorUpload;
                        const base64 = reader.result.split(',')[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        
                        this.dialog.block('Uploading...')

                        const formData = new FormData();

                        formData.append('file', blobInfo.blob(), file.name);
                        formData.append('collection_name', 'articles');

                        Http.post('media', formData, {
                            params: {
                                query: {
                                    media: ['url']
                                }
                            }
                        })
                        .then(({ data }) => {
                            /* call the callback and populate the Title field with the file name */
                            cb(data.data.url, { title: file.name, text: file.name });
                            this.dialog.unblock();
                        })
                        .catch(() => {
                            this.dialog.unblock();
                        })
                    };
                    reader.readAsDataURL(file);
                };

                input.click();
            },
            imagesUploadHandler(blobInfo, success, failure, progress) {
                const config = {
                    params: {
                        query: {
                            media: ['url']
                        }
                    },
                    onUploadProgress: (progressEvent) => {
                        progress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                    }
                }

                const formData = new FormData();

                formData.append('file', blobInfo.blob(), blobInfo.filename());
                formData.append('collection_name', 'articles');

                Http.post('media', formData, config)
                .then(({ data }) => {
                    success(data.data.url);
                })
                .catch(({ response }) => {
                    failure(response.data.message);
                })
            }
        }
    }
</script>
